@import './App.scss';

.background__postponed {
  @include warning_delivery_status_box;
}

.background__new {
  @include new_status_box;
}

.background__ready {
  @include ready_status_box;
}

.background__warning_delivery {
  @include warning_delivery_status_box;
}

.background__danger_delivery {
  @include danger_delivery_status_box;
}

.background__preparing {
  @include preparing_status_box;
}

.background__completed {
  @include completed_status_box;
}

.background__printed {
  @include new_status_box;
}

.background__cancelled {
  @include cancelled_status_box;
}

.background__pending {
  @include new_status_box;
}

.color__postponed {
  @include warning_delivery_status_box_color;
}

.color__new {
  @include new_status_box_color;
}

.color__ready {
  @include ready_status_box_color;
}

.color__warning_delivery {
  @include warning_delivery_status_box_color;
}

.color__danger_delivery {
  @include danger_delivery_status_box_color;
}

.color__preparing {
  @include preparing_status_box_color;
}

.color__completed {
  @include completed_status_box_color;
}

.color__printed {
  @include new_status_box_color;
}

.color__cancelled {
  @include cancelled_status_box_color;
}

.color__pending {
  @include new_status_box_color;
}

.cart-wrapper-employee-status_preparing {
  @include status_circle;
  background-color: $cart_preparing_circle;
}

.cart-wrapper-employee-status_ready {
  @include status_circle;
  background-color: $cart_ready_circle;
}

.cart-wrapper-employee-status_completed {
  @include status_circle;
  background-color: $cart_completed_circle;
}

.cart-wrapper-employee-status_new {
  @include status_circle;
  background-color: $cart_new_circle;
}

.cart-wrapper-employee-status_printed {
  @include status_circle;
  background-color: $cart_new_circle;
}

.select_status___preparing {
  background-color: $cart_preparing_circle !important;
  .cart-items {
    background-color: blue !important;
  }
}
.select_status___completed {
  background-color: $cart_completed_circle !important;
  color: #000;
}
.select_status___ready {
  background-color: $cart_ready_circle !important;
}
.select_status___new {
  background-color: $cart_new_circle !important;
  color: #000;
}
.select_status___cancel {
  background-color: #e26a6a !important;
  color: #fff;
  width: 100%;
  text-align: center;
  display: block;
}

.divider {
  .cart-items {
    border-top: none !important;
    background-image: url('../assets/images/triangle.png');
    background-position: top;
    background-repeat: repeat-x;
    margin-top: -10px;

    div:nth-child(1) {
      padding-top: 10px;
    }
  }
}

.unique {
  min-height: 302px;
  div:nth-child(5) {
    border-bottom: none !important;
  }
}

.divider {
  .has-footer {
    min-height: 450px;
  }
}

.divider-bottom {
  .cart-items {
    border-bottom: none !important;
    background-image: url('../assets/images/triangle.png');
    background-repeat: repeat-x;
    background-position: bottom;

    div:nth-child(5) {
      border-bottom: $default_border 1px;
      // border-bottom: none;
    }
  }
}

.extras {
  .cart-items {
    background-image: url('../assets/images/triangle.png'),
      url('../assets/images/triangle.png');
    background-position: top, bottom;
    background-repeat: repeat-x;
    padding-bottom: 20px;
    border-bottom: none !important;
    height: 485px;
    width: 97%;

    div:nth-child(5) {
      border-bottom: $default_border 1px;
    }
  }
}

.cart-tools-wrapper-main {
  min-width: 350px;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  > div {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .category-notify svg {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    float: right;
  }

  .cart-tools-wrapper {
    margin-top: 25%;
    z-index: 2;
    min-height: 98%;
    width: 97%;
    left: 5px;
  }

  .cart-tools-wrapper-backdrop {
    display: none;
    padding-bottom: 30px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    border-radius: 10px;
    min-height: 100%;
    width: 100%;
  }
}

.cart-wrapper {
  min-width: 210px;
  color: $cart_number_text_color;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: #fff;

  letter-spacing: 0.3px;
  line-height: 1.3em;
  font-size: 16pt;

  .header-container {
    display: flex;

    div:nth-child(1) {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 0.8rem;
        font-weight: 700;
        margin-bottom: 5px;
      }

      p:nth-child(2) {
        width: 70%;
        font-weight: 600;
        justify-content: center;
        padding: 9px;
        border-radius: 10px;
      }
    }
  }

  .status_container {
    @include status_circle;
    width: auto;
    padding: 7px;
    color: #fff;
    font-size: 0.6rem;
    margin-right: 0px;
    display: flex;
    align-items: center;
    div {
      margin-left: 10px;
    }
  }

  .bottom_status_container {
    padding: 9px 15px 9px 15px;
    font-weight: 700;
  }

  .radius-12 {
    border-radius: 12px;
  }

  .receipt_number {
    @include cart_number;
  }

  .cartTools {
    margin-top: 10px;

    button {
      margin-right: 15px;
    }

    .actions {
      display: flex;
      margin: 0 -10px;
    }
  }

  header {
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-flow: column;
    padding: 8px;
    text-transform: capitalize;
    padding-bottom: 0px;

    p {
      margin-bottom: 10px;
      font-size: 1em;
      font-weight: 600;
    }

    h2 {
      display: flex;
      // justify-content: center;
      align-items: center;
    }

    .note {
      color: #cc0303;
      clear: both;
      font-size: 1.2em;
      text-align: center;
      margin-top: 10px;
      text-decoration: underline;
    }

    .header {
      display: flex;
      flex-flow: column;
      cursor: pointer;

      &:hover {
        filter: opacity(90%);
      }

      .top {
        display: flex;
        flex-flow: row;
        text-transform: uppercase;

        .left {
          text-align: left;
          width: 100%;
        }

        .right {
          text-align: right;
          width: 100%;
        }
      }

      .bottom {
        text-align: left;
        padding: 15px 0 15px 0;
      }
    }

    .tooltip {
      position: relative;
      display: flex;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      align-self: center;
      width: 120px;
      background-color: #000000c9;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    word-wrap: break-word;

    .footer-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .status_icon {
      -webkit-text-stroke: 2px;
      font-weight: 600;
    }

    .footer-container + div {
      margin-top: 10px;
    }

    .filter-icons {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    .margin-left-25 {
      margin-left: 25px;
    }

    .top {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 5px;

      p {
        font-size: 12pt !important;
        font-family: Arial, Helvetica, sans-serif;
        margin: 10px 0;
        text-transform: uppercase;
      }
    }

    .filters {
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
      text-align: center;
    }
  }

  .cart-tools {
    position: absolute;
  }

  .cart-items {
    background-color: $items_background;
    margin-bottom: 10px;

    .bottom {
      display: flex;
      flex-direction: column;
      padding-left: 22px;

      p {
        font-weight: 400 !important;
        font-size: 0.85rem !important;
      }
    }
    .item-right {
      justify-content: flex-end;

      span {
        -webkit-text-stroke: 2.5px;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .item-left {
      justify-content: flex-start !important;

      p {
        width: auto !important;
      }

      p + p {
        margin-left: 10px;
      }
    }

    .left {
      display: flex;
      text-align: left;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        justify-content: flex-end;
        flex: 3;

        p {
          max-width: 100%;
          width: 100%;
        }
      }

      h2 {
        display: flex;
        align-items: center;
        margin-right: 5px;
        min-width: 200px;
      }

      .qtd-status__preparing {
        background-color: $cart_preparing_circle;
      }

      .qtd-status__pending {
        background-color: $cart_new_circle;
      }

      .qtd-status__ready {
        background-color: $cart_ready_circle;
      }

      .qtd-status__completed {
        background-color: $cart_completed_circle;
      }

      .qtd-status__new {
        background-color: $cart_new_circle;
      }

      .qtd-status__printed {
        background-color: $cart_new_circle;
      }

      .cart-wrapper-addons {
        font-weight: 900;
        word-break: break-word;
      }

      .checkbox-item {
        margin: 0px;
      }
    }

    .item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 10px 10px 10px !important;
      cursor: pointer;
      transform: none;

      &:hover {
        background-color: #eee;
      }

      p {
        margin-bottom: 0px;
        font-size: 20px;
        font-weight: 700;
      }

      .item-note {
        color: #cc0303;
        font-size: 0.75em;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 0px;
        margin-left: 25px;
        text-align: left;
      }
    }

    .seat-separator {
      background-color: #292929;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: right;
      align-items: center;
      color: #fff;
      height: 40px;

      i {
        font-size: 1.2em;
      }

      span {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-align: right;
        padding-right: 15px;
      }
    }

    .category-separator {
      background-color: #292929;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      padding-left: 10px;

      span {
        padding: 5px;
        vertical-align: middle;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 0.85em;
        color: #fff;
        width: 100%;
        text-align: left;
      }
    }

    .right {
      text-align: left;
      margin: 0 0 0 0;

      &.cart-employee {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .cart-wrapper-employee {
        font-size: 0.7em !important;
        font-weight: 900;
        text-transform: uppercase;
      }

      span {
        font-size: 1em;
      }

      img {
        margin-right: 3px;
      }
    }
  }

  .customer-details {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .customer-details-header {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 10px 0 10px 10px;
      align-content: space-around;
      cursor: pointer;
      text-align: left;

      &:hover {
        background: rgba(0, 0, 0, 0.25);
        border-radius: 10px;
      }

      .m-l-12 {
        flex: 1;
        margin-left: 5px;
      }
    }

    .customer-details-content {
      text-align: left;
      margin-left: 10px;

      p:first-child {
        padding-top: 5px;
      }
      p:last-child {
        padding-bottom: 5px;
      }

      p {
        line-height: 30px;

        span {
          font-size: 1em;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }

    .close {
      display: none;
    }

    .open {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      opacity: 1 !important;
      height: 100%;
      max-height: fit-content;
    }

    p {
      margin: 1px !important;
    }
  }

  .icon-status {
    display: flex;
    justify-content: right;
    flex: 1;

    .item-status-icons {
      display: flex;
      justify-content: right;
    }

    .countr-icon {
      margin-left: 5px;
      height: 35px;
      width: 35px;
      border-width: thick;
      border-style: double;

      span {
        margin: 0;
        padding: 0;
      }
    }

    .update-cart-icon {
      padding: 0 !important;
      bottom: 5px;
      right: 5px;
      border-color: #ffffff !important;

      span i {
        color: #fff !important;
      }
    }

    .countr-icon.printed {
      border-color: #35495e;

      span i {
        color: #35495e;
      }
    }

    .countr-icon.preparing {
      background-color: #f79d34;

      span i {
        color: white;
        background-color: red;
      }
    }

    .countr-icon.ready {
      border-color: #f79d34;

      span i {
        color: #3bae70;
      }
    }

    .countr-icon.completed {
      border-color: #f79d34;

      span i {
        color: #64c2ea !important;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.clock {
  span {
    color: #fff !important;
    font-weight: 700;
  }
}
