#grouped-wrapper::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
#grouped-wrapper::-webkit-scrollbar-thumb {
  background: orange;
}

#grouped-wrapper {
  .product-media {
    h2 {
      margin-top: 30px;
    }
    .countr-product-medias {
      margin: 15px 0 30px 0;
    }
  }

  .grouped {
    border-radius: 0 0 0 10px;
    grid-auto-flow: column;
    display: grid;
    align-items: flex-end;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;

    .grouped-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: visible;
      margin-right: 15px;
      max-width: 300px;

      span {
        text-transform: uppercase;
        font-size: 0.7em;
      }

      h2 {
        font-size: 0.8em;
        border-bottom: 1px solid #ababab;
        padding-bottom: 15px;
        display: flex;
        align-items: center;

        span {
          margin-right: 10px;
          line-height: 0;
        }
      }

      ul {
        padding-top: 10px;
        list-style: none;
        border-bottom: 1px solid #ababab;
        margin-bottom: 15px;

        li {
          font-size: 1rem;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            margin-right: 10px;
          }
        }
      }

      .group-card-actions {
        justify-content: space-evenly;
        padding: 0px;

        i {
          padding: 5px;
          font-size: 1em;
          border-radius: 50%;
          text-shadow: 1.5px 1.5px 0px rgba(150, 150, 150, 0.5);
        }
      }

      .grouped-footer {
        margin: 15px;
        display: flex;
        flex-direction: row;

        div {
          margin-left: 15px;
          cursor: pointer;
          i {
            background-color: rgba(255, 255, 255, 0.274);
            font-size: 3em;
            border-radius: 33%;
          }
        }

        .prepare-group-icon {
          i {
            color: #f5921d;
          }
        }

        .ready-group-icon {
          i {
            color: rgb(10, 128, 26);
          }
        }
      }

      .countr-icon.printed {
        border-color: #35495e;

        span i {
          color: #35495e;
        }
      }

      .countr-icon__preparing {
        border-color: #f79d34;

        span i {
          color: white;
          padding: 8px;
          background-color: #f79d34;
          border: solid 2px gray;
        }
      }

      .countr-icon__ready {
        border-color: #f79d34;

        span i {
          color: white;
          padding: 8px;
          border: solid 2px gray;
          background-color: #3bae70;
        }
      }

      .countr-icon.completed {
        border-color: #f79d34;

        span i {
          color: #64c2ea !important;
        }
      }

      .countr-icon__highlight {
        span i {
          color: white;
          padding: 8px;
          border: solid 2px gray;
          background-color: #35495e;
        }
      }

      .hidden {
        visibility: hidden;
      }
    }
  }
}
