@import './App.scss';

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: $main_background;
}

// RESET
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;

  letter-spacing: 0.3px;
  line-height: normal;
  font-size: 16pt;
}

html,
body {
  min-height: -webkit-fill-available;
}

html {
  color: $default_color;
}

#main {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 10px;
}

.withoutorders {
  justify-content: center;
  height: calc(100vh - 150px);
}

.small-x-qty {
  margin-right: 10px;
  min-width: 35px;

  span {
    font-size: 0.8em;
  }
}

.m-icon {
  font-size: $m-ico;
}

.clickable {
  cursor: pointer;
}

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;

  i {
    font-size: 10em;
    cursor: pointer;
  }

  p {
    max-width: 200px;
  }
}

.awaiting-payment {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.send-archive {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: #f44336 !important;
}

#grid-grouped-wrapper {
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;
  display: flex;
  min-height: calc(100vh - 135px);
}

#grid-grouped-wrapper-horizontal {
  justify-content: space-between;
  min-width: 100%;
  display: flex;
  min-height: calc(100vh - 135px);
}

#grid-wrapper-main::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  scrollbar-width: none;
}

// #grid-wrapper-main::-webkit-scrollbar-thumb {
//   background: orange;
// }

#grid-wrapper-main {
  display: grid;
  grid-auto-columns: min-content;

  grid-gap: 0.5rem;
  grid-auto-flow: column;

  padding-bottom: 30px;

  overflow-x: auto;
}

#grid-wrapper-main-horizontal {
  max-width: 100%;
  margin-right: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 0.5rem;
  align-items: flex-start;
  grid-auto-flow: row dense;
  grid-auto-rows: min-content;
}

.completed-screen-grid {
  @extend #grid-wrapper-main;
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.settings-multiple-select {
  select {
    border: solid #b6c2cd 1px;
    border-radius: 5px;
    margin-top: 10px;
    width: 160px;
    option {
      padding: 8px;
    }
    option:nth-child(odd) {
      background-color: #b6c2cd;
    }
  }
}

.employeesDropdown {
  option {
    height: 100px;
  }
}

.time_button {
  border-radius: 4px;
  border: 3px solid $default_active_button;
  height: 35px;
  width: 60px;
  font-size: 12pt;
  background-color: transparent;
  font-weight: 700;
  color: $default_active_button;
}

.tool-status-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.status_button {
  &:not(:first-child) {
    margin-top: 10px;
  }

  border-radius: 5px;
  border: none;
  height: 70px;
  background-color: $default_active_button;

  display: flex;
  align-items: center;

  text-shadow: 1.5px 1.5px 0px rgba(150, 150, 150, 0.5);

  i {
    padding-left: 15px;
    color: #fff;
  }

  p {
    font-size: 1em;
    letter-spacing: 0.3px;
    color: #fff;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
  }

  &:disabled {
    cursor: initial;
    opacity: 0.7;
  }
}

.status_button_cancel {
  border-radius: 4px;
  border: none;
  height: 60px;
  width: 150px;
  font-size: 0.8em;
  font-weight: 500;
  color: #fff;
  background-color: $default_active_button;
  opacity: 0.7;
}

/* Device Registration */
.registration {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .registration-card {
    border-radius: 10px;
  }
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: $default_hover_button
    radial-gradient(circle, transparent 1%, $default_hover_button 1%)
    center/15000%;
}
.ripple:active {
  background-color: #4e698655;
  background-size: 100%;
  transition: background 0s;
}

.staging {
  width: 100%;
  height: 20px;
  background-color: #f6921d;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }
}

.action-btn {
  width: fit-content;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 10px !important;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: scale(0.98);
    background: rgba(0, 0, 0, 0.25);
  }

  .icon {
    font-size: 16px;
    color: #fff;
  }
  .text {
    font-size: 16px;
    text-transform: capitalize;
    padding: 2px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400 !important;
    font-style: normal !important;
    letter-spacing: 0.3px !important;
    color: #fff;
  }
}

.orders-switch-box {
  color: #fff;

  @media screen and (max-width: 454px) {
    flex: 1;
  }

  .middle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
  }

  .left {
    span {
      font-size: 3em;
      font-weight: 600;
      text-align: right;
    }
  }

  .bottom {
    text-align: center;
    display: block;

    small {
      text-transform: uppercase;
    }
  }
}

.scale-hover {
  /* Medium Devices, Desktops */
  @media (min-width: 1025px) {
    transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
  }
}

.highlighted {
  animation-name: zoom;
  animation-duration: 0.8s;
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
  }
}

.pin-input {
  height: 40px;
  text-align: center;
  font-weight: bold;
}

.cancel-btn-color {
  color: #f44336 !important;
}

.confirm-cancel-order-btn {
  width: 100% !important;
  font-weight: bold !important;
  margin-top: 25px !important;
  padding: 15px !important;
}

.active-cancel-order-btn {
  background-color: #64c2ea !important;
  color: #fff !important;
}

.disabled-cancel-order-btn {
  background-color: #e0e0e0 !important;
  color: #c7c7c7 !important;
}

.line {
  min-width: 290px !important;
  button {
    margin: 10px;
    padding: 15px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    background-color: #727171 !important;
  }
}

.scroll-arrow {
  position: absolute;
  color: #5f5f5f;
  right: 0;
  top: 50%;
  font-size: 3rem;
  font-weight: 1000;
}

.scroll-arrow-left {
  position: absolute;
  color: #5f5f5f;
  left: 15px;
  top: 50%;
  font-size: 3rem;
  font-weight: 1000;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}
