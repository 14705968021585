#app-login {
  display: flex;
  width: fit-content;
  margin: auto;

  height: 100vh;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    align-items: stretch;
    overflow: visible !important;
  }

  .login-content {
    background: rgba(232, 232, 232, 1);
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 30px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    max-height: 600px;
    border-top: solid 3px #f6921d;
    border-bottom: solid 3px #e36a6a;
    border-right: solid 3px #26a560;
    border-left: solid 3px #00adee;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      border-radius: 0;
      text-align: center;
      width: 100%;
      min-height: auto;
    }

    .save-store-btn {
      height: 60px;
      max-width: 300px;
      background-color: rgb(246, 146, 29);
    }

    .cancel-store-btn {
      height: 50px;
      min-width: 200px;
      background-color: rgb(246, 72, 29);
    }

    .device-name {
      max-width: 400px;
    }

    input {
      height: 50px;
    }

    .small-text {
      p {
        font-size: 0.8em;
        text-align: left;
        margin-bottom: 15px;
      }
    }

    .notes {
      max-width: 300px;
      text-transform: uppercase;
      text-align: center;

      p {
        margin-bottom: 10px;
        font-size: 0.6rem;
      }
    }

    .stores-label {
      span {
        font-size: 0.8rem;
      }
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      img {
        // width: 100%;
        // height: 30%;
        margin: 10px 0 10px 0;
      }
    }

    img {
      max-width: 100%;
    }

    .center {
      text-align: center;
    }

    .login-button-width {
      height: 80px;
      min-width: 300px;
      background-color: rgb(246, 146, 29);
      margin: 10px 0 10px 0;
    }
  }

  .store-selection-login {
    max-height: 220px;
    overflow: auto;
  }
}
