@import '../assets/style.css';

// CART STYLE
$cart_background: #f7f8f8;
$items_background: #f7f8f8;
$cart_title: 1.3em;
$cart_number_text_color: #707070;

$status_counter_background: #35495e;
$header_background: #5f5f5f;
$main_background: #bfbfbf;

// CART STATUS
$toolbar_default: #cccccc;
$cart_preparing: #f79d34;
$cart_preparing_circle: #f79d34;
$cart_ready: #3bae70;
$cart_ready_circle: #3bae70;
$cart_new: #35495E;
$cart_new_circle: #cccccc;
$cart_printed: #a4d9ef;
$cart_completed: #64c2ea;
$cart_completed_circle: #64c2ea;
$cart_warning: #2e8fd4;
$cart_danger: #f73444;
$cart_cancelled: #e26a6a;

@mixin status_circle {
  height: 15px;
  width: 15px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
}

@mixin cart_number {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0px;
}

@mixin default_box {
  background-color: $cart_background;
}

@mixin new_status_box {
  background-color: $cart_new;
}

@mixin cancelled_status_box {
  background-color: $cart_cancelled;
}

@mixin preparing_status_box {
  background-color: $cart_preparing;
}

@mixin warning_delivery_status_box {
  background-color: $cart_warning;
}

@mixin danger_delivery_status_box {
  background-color: $cart_danger;
}

@mixin ready_status_box {
  background-color: $cart_ready;
}

@mixin completed_status_box {
  background-color: $cart_completed;
}

@mixin printed_status_box {
  background-color: $cart_printed;
}

@mixin default_box_color {
  color: $cart_background;
}

@mixin new_status_box_color {
  color: $cart_new;
}

@mixin cancelled_status_box_color {
  color: $cart_cancelled;
}

@mixin preparing_status_box_color {
  color: $cart_preparing;
}

@mixin warning_delivery_status_box_color {
  color: $cart_warning;
}

@mixin danger_delivery_status_box_color {
  color: $cart_danger;
}

@mixin ready_status_box_color {
  color: $cart_ready;
}

@mixin completed_status_box_color {
  color: $cart_completed;
}

@mixin printed_status_box_color {
  color: $cart_printed;
}


.divider__postponed {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include warning_delivery_status_box;
}

.divider__new {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include new_status_box;
}

.divider__ready {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include ready_status_box;
}

.divider__warning_delivery {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include warning_delivery_status_box;
}

.divider__danger_delivery {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include danger_delivery_status_box;
}

.divider__preparing {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include preparing_status_box;
}

.divider__completed {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include completed_status_box;
}

.divider__printed {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include new_status_box;
}

.divider__cancelled {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include cancelled_status_box;
}

.divider__pending {
  padding: 5px;
  transition: all 300ms ease;
  color: #fff;
  @include new_status_box;
}

// DEFAULT
$default_font_size: 0.748;
$default_color: #666766;
$default_active_button: #35495e;
$default_hover_button: #4e6986;
$default_border: solid #b6c2cd;

// CLOCK
$clock_red_alert: #ae272d;

// ICONS
$m-ico: 1.2rem;
