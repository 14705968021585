.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1200;
  top: 0;
  left: -300px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.3s;
}

.sidenav-backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 1200;
  display: none;
  top: 0;
}

.sidenav .close-btn {
  position: absolute;
  top: 10px;
  left: -300px;
  font-size: 36px;
  margin-left: 24px;
  color: #ffffff;
}

.main-menu-header {
  background-color: #1d2127;
  padding: 10px;
  color: #fff;
  box-shadow: 2px 5px 15px -1px grey;
}

.main-menu-back {
  position: absolute !important;
  left: 24px;
  color: #fff !important;
}

.main-menu-header-items {
  color: #fff !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  display: block;
  overflow: hidden;
}

.main-menu-header-device:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.main-menu-header-logo {
  height: 44px;
  position: relative;
}

.main-menu-app-version {
  text-align: center;
  font-size: 10px;
}

.main-menu-header-divider {
  margin-top: 16px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.sidenav-content {
  height: 100%;
  background-color: #ffffff;
}

.menu-userinfo {
  margin: 15px 0;
  width: 100%;

  p {
    display: flex;
    word-break: break-all;
    max-width: 100%;
    margin-bottom: 15px;
    align-items: center;
    font-size: 0.7rem;

    span {
      margin-right: 10px;
    }
  }
}

.main-menu {
  span {
    margin-right: 12px;
  }
}

.logout-btn {
  position: fixed !important;
  bottom: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  width: 300px !important;
}

.highlight:hover {
  background-color: #121315;
}

.app-logo {
  height: 80px;
  width: 100%;
  object-fit: contain;
}
