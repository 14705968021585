@import './App.scss';

.tools {
  margin-top: 50%;
  border-radius: 10px;
  width: 80%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.5);

  .tools-header {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    overflow: hidden;
    text-align: center;

    span {
      max-width: 80%;
      padding: 0 10px;
      font-weight: 800;
    }
  }

  .tools-sub-header {
    position: relative;
    bottom: 20px;
    font-size: 0.7em;
    color: rgba(0, 0, 0, 0.5);
  }

  .toots-select {
    max-width: 300px;
    min-width: 200px;
  }

  .tools-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);

    button {
      width: 130px;
    }
  }

  select {
    width: 95%;
    margin: 20px;
    height: 50px;
    text-indent: calc(50% - 90px);
  }

  .btn-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    // margin-top: 20px;
  }
}

.item-size {
  span {
    font-size: 18px;
  }

  p span {
    font-size: 15px;
  }
}
.product-tools {
  display: flex;

  .left-tools-header {
    width: 80%;
  }

  .right-tools-header {
    width: 20%;
    color: red;
    cursor: pointer;
  }
}

.receipt_number {
  @include cart_number;
}

.padding-all-8 {
  padding: 8px;
  width: calc(100% - 16px);
}

.header-container {
  display: flex;

  div:nth-child(1) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  div:nth-child(2) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    flex-direction: column;

    p {
      font-size: 0.8rem;
      margin-bottom: 0px;
    }
  }
}

.status_container {
  @include status_circle;
  height: auto;
  width: auto;
  padding: 7px;
  color: #fff;
  font-size: 0.8rem;
  margin-right: 0px;
}

.width_100 {
  width: calc(100% - 10px);
}
