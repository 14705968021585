@import './App.scss';

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $header_background;
}

.filter-icons-header {
  height: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 4px;

  .logo {
    align-items: flex-start;
    max-width: 300px;
    flex: 1;
  }

  .settings-icon {
    flex: 1;
    max-width: 300px;
    cursor: pointer;
    display: flex;
    justify-content: left;
    margin-left: 15px;
    align-items: center;

    i {
      font-size: 3em;
      color: #fff;
    }
  }

  .group-orders-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 20px;

    border-radius: 50px;
    background-color: #272727;

    $color-shadow: #141414;
    $color-white: #212121;

    color: #61677c;
    font-weight: bold;
    box-shadow: -1px -1px 5px $color-white, 2px 2px 5px $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      box-shadow: 1px 1px 5px $color-white, 2px 2px 5px $color-shadow;
    }

    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow,
        inset -1px -1px 2px $color-white;
    }

    span {
      font-size: 2rem;
    }

    .active {
      color: #fff;
    }
  }

  img {
    max-width: 300px;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .order-counter {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1;

    .status-counter {
      @include status_circle;
      width: fit-content;
      background-color: $status_counter_background;
      padding: 5px;
      font-weight: 500;
      font-size: 0.8rem;
    }

    .status-container {
      display: flex;
      align-items: center;
    }

    span {
      color: rgba(256, 256, 256) !important;
      font-weight: bolder;
      font-size: 1.2em;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0 10px 0 0;
      padding: 2px;
      border-radius: 15%;
      word-break: break-all;
      text-align: center;
    }
  }

  .clock {
    flex: 1;
    max-width: 300px;
    text-align: flex-end;

    span {
      color: #fff !important;
      font-size: 2em;
      font-weight: 600;
    }
  }

  .bulk-button {
    margin-right: 3%;
    .bar-button {
      color: #fff;
      border: 2px solid #fff;
      float: right;
      font-weight: 700;
      transition: all 0.6s ease;
    }

    .bar-button:hover {
      background-color: #fff;
      color: black;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }
  }

  @media screen and (max-width: 750px) {
    .clock {
      display: none;
      width: 1px;
    }
  }
}

.top-filter-menu {
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 20px 20px 50px #00d2c6, -30px -30px 60px #00ffff;

  p {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    span {
      font-size: 3em;

      &:last-child {
        font-size: 100%;
        text-transform: uppercase;
        margin-left: 10px;
      }
    }
  }

  &:hover {
    filter: opacity(0.9);
  }
}

.header {
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}

.sub-header {
  background-color: $toolbar_default !important;
  top: 100px !important;

  .toolbar {
    display: flex;
    flex-flow: row;
  }

  .f-1 {
    flex: 1;
  }

  .upper {
    text-transform: uppercase;
    color: rgba(29, 33, 39, 1);
    font-size: 22px;
  }

  .pages {
    width: 100%;
    text-align: center;
  }

  .circle {
    background: black;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    margin-left: 3px;
    margin-right: 3px;
    display: inline-block;
  }

  .circle-disabled {
    background: rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    height: 5px;
    width: 5px;
    margin-left: 3px;
    margin-right: 3px;
    display: inline-block;
    cursor: pointer;
  }
}
